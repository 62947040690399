import * as Styled from "./Navbar.styled";
import ReSygnalDark from "../../assets/resygnal-logos/ReSygnal_Dark.svg";
import ReSygnalLight from "../../assets/resygnal-logos/ReSygnal_Light.svg";
import { NavbarProps } from "./Navbar.types";
import { Connect } from "../../pages/connect/Connect";
import { DragHandle } from "@mui/icons-material";
import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { ResygnalTheme } from "../../ResygnalTheme";
import { useState } from "react";
import NownWhite from "../../assets/enoun-logos/WhiteColor.svg";
import NownBlack from "../../assets/enoun-logos/BlackColor.svg";

export const Navbar = ({ useLightMode }: NavbarProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const mobile = useMediaQuery("(min-width:1020px)");
  const hideLogo = useMediaQuery("(min-width:575px)");

  const onMenuClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  const getNownLogo = () => {
    return !hideLogo ? null : (
      <>
        <Divider
          sx={{
            height: "50px",
            width: "0.1px",
            backgroundColor: !useLightMode
              ? ResygnalTheme.dark
              : ResygnalTheme.light,
          }}
          orientation="vertical"
        />
        <Box display="flex" flexDirection="column" position="relative">
          <Chip
            sx={{
              width: "fit-content",
              marginLeft: "auto",
              right: "-29px",
              top: "-8px",
              fontFamily: "'Ubuntu', sans-serif !important",
              position: "absolute",
            }}
            size="small"
            label="New!"
            color="success"
          />
          <a
            rel="noreferrer"
            href="https://marketing.enoun.app"
            target="_blank"
          >
            <img
              alt="enoun"
              style={{ width: 80 }}
              src={useLightMode ? NownWhite : NownBlack}
            />
          </a>
        </Box>
      </>
    );
  };

  return (
    <Styled.Wrapper useLightMode={useLightMode}>
      <Styled.ThemedLink to="/">
        <Styled.Logo
          mobile={mobile}
          src={!useLightMode ? ReSygnalDark : ReSygnalLight}
          alt="reSygnal"
        />
      </Styled.ThemedLink>
      {!mobile ? (
        <Stack direction="row" alignItems="center" gap={3}>
          <IconButton
            onClick={onMenuClick}
            style={{
              backgroundColor: !useLightMode
                ? ResygnalTheme.dark
                : ResygnalTheme.light,
            }}
          >
            <DragHandle
              fontSize="small"
              style={{
                color: useLightMode ? ResygnalTheme.dark : ResygnalTheme.light,
                cursor: "pointer",
              }}
            />
          </IconButton>
          {getNownLogo()}
          <Popper
            sx={{ zIndex: 10 }}
            open={menuOpen}
            anchorEl={anchorEl}
            role={undefined}
            placement="bottom"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                    <MenuList
                      id="composition-menu"
                      aria-labelledby="composition-button"
                    >
                      <Styled.ThemedLink
                        to="/"
                        onClick={() => setMenuOpen(false)}
                      >
                        <MenuItem>
                          <Styled.Text useLightMode={false}>Home</Styled.Text>
                        </MenuItem>
                      </Styled.ThemedLink>
                      <Styled.ThemedLink
                        to="/products"
                        onClick={() => setMenuOpen(false)}
                      >
                        <MenuItem>
                          <Styled.Text useLightMode={false}>
                            Products
                          </Styled.Text>
                        </MenuItem>
                      </Styled.ThemedLink>
                      <MenuItem>
                        <Connect onDialogClose={() => setMenuOpen(false)} />
                      </MenuItem>
                      <Styled.ThemedLink
                        to="/about"
                        onClick={() => setMenuOpen(false)}
                      >
                        <MenuItem>
                          <Styled.Text useLightMode={false}>About</Styled.Text>
                        </MenuItem>
                      </Styled.ThemedLink>
                      <Styled.ThemedLink
                        to="/faq"
                        onClick={() => setMenuOpen(false)}
                      >
                        <MenuItem>
                          <Styled.Text useLightMode={false}>FAQ</Styled.Text>
                        </MenuItem>
                      </Styled.ThemedLink>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Stack>
      ) : (
        <Styled.LinkWrapper mobile={mobile}>
          <Styled.ThemedLink to="/">
            <Styled.Text useLightMode={useLightMode}>Home</Styled.Text>
          </Styled.ThemedLink>
          <Styled.ThemedLink to="/products">
            <Styled.Text useLightMode={useLightMode}>Products</Styled.Text>
          </Styled.ThemedLink>
          <Connect useLightMode={useLightMode} />
          <Styled.ThemedLink to="/about">
            <Styled.Text useLightMode={useLightMode}>About</Styled.Text>
          </Styled.ThemedLink>
          <Styled.ThemedLink to="/faq">
            <Styled.Text useLightMode={useLightMode}>FAQ</Styled.Text>
          </Styled.ThemedLink>
          {getNownLogo()}
        </Styled.LinkWrapper>
      )}
    </Styled.Wrapper>
  );
};
