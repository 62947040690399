import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ResygnalTheme } from "../../ResygnalTheme";

export const Text = styled(Typography)<{ useLightMode?: boolean }>`
  cursor: pointer;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.useLightMode ? ResygnalTheme.light : ResygnalTheme.dark};
  font-size: 18px !important;
  &:hover {
    color: grey;
  }
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Wrapper = styled.div<{ useLightMode?: boolean }>`
  display: flex;
  max-width: 1400px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
  background: ${(props) =>
    !props.useLightMode ? ResygnalTheme.light : ResygnalTheme.dark};
`;

export const ThemedLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Logo = styled.img<{ mobile: boolean }>`
  width: ${(props) => (props.mobile ? "230px" : "230px")};
  vertical-align: bottom;
`;

export const LinkWrapper = styled.div<{ mobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  width: ${(props) => (props.mobile ? "fit-content" : "min-content")};
`;
