import { Navbar } from "../navbar/Navbar";
import { PageWrapperProps } from "./PageWrapper.types";
import { Footer } from "../footer/Footer";
import * as Styled from "./PageWrapper.styled";
import { ResygnalTheme } from "../../ResygnalTheme";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useEffect } from "react";

export const PageWrapper = ({
  children,
  style,
  useLightMode,
  childWrapperStyled,
}: PageWrapperProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Styled.Wrapper style={style}>
      <Navbar useLightMode={useLightMode} />
      <div
        style={{
          maxWidth: 1400,
          margin: "auto",
          minHeight: "calc(100% - 250px)",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: 30,
          backgroundColor: useLightMode
            ? ResygnalTheme.dark
            : ResygnalTheme.light,
          ...childWrapperStyled,
        }}
      >
        <FadeIn>{children}</FadeIn>
      </div>

      <Footer />
    </Styled.Wrapper>
  );
};
