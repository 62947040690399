import { FieldArray, Form, Formik } from "formik";
import * as Styled from "./Connect.styled";
import * as NavStyled from "../../components/navbar/Navbar.styled";
import * as FooterStyled from "../../components/footer/Footer.styled";
import { yupSchema } from "./Connect.utils";
import { ConnectProps, FormValues, PrettyError } from "./Connect.types";
import {
  Alert,
  CircularProgress,
  Dialog,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { useRef, useState } from "react";
import { ResygnalTheme } from "../../ResygnalTheme";
import FadeIn from "react-fade-in";
import { ConnectFn } from "../../backends/commands/ConnectFn";
import AlertToaster from "../../components/alert/Alert";
import ReCAPTCHA from "react-google-recaptcha";

export const Connect = ({
  useLightMode,
  isFooter,
  onDialogClose,
  openProp,
}: ConnectProps) => {
  const [open, setOpen] = useState<boolean>(openProp ?? false);
  const [error, setError] = useState<string>();
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const reCaptchaRef = useRef<ReCAPTCHA | null>(null);

  const handleClose = () => {
    setError(undefined);
    onDialogClose && onDialogClose();
    setOpen(false);
  };

  const handleError = (error?: string) => {
    setError(error);
  };

  const handleSuccess = () => {
    setError(undefined);
    handleClose();
    setShowSuccessAlert(true);

    setTimeout(function () {
      setShowSuccessAlert(false);
    }, 3000);
  };

  const onSubmit = async (formValues: FormValues) => {
    const token = await reCaptchaRef?.current?.executeAsync();

    if (!token) {
      handleError(PrettyError.TOKEN);
      return;
    }

    const response = await ConnectFn(formValues, token);

    if (response.error) {
      handleError("An unknown error occured.");
      return;
    }

    handleSuccess();
  };

  return (
    <>
      {isFooter ? (
        <FooterStyled.RegularText isLink onClick={() => setOpen(true)}>
          Inquiry Form
        </FooterStyled.RegularText>
      ) : (
        <NavStyled.Text
          onClick={() => setOpen(true)}
          useLightMode={useLightMode}
        >
          Contact
        </NavStyled.Text>
      )}

      <>
        {showSuccessAlert ? (
          <AlertToaster message="Thanks for reaching out!" severity="success" />
        ) : null}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          size="invisible"
          ref={reCaptchaRef}
        />
        <Styled.CloseWrapper>
          <IconButton component="label" onClick={handleClose}>
            <Styled.ThemedClose />
          </IconButton>
        </Styled.CloseWrapper>
        <Styled.Wrapper>
          <FadeIn>
            <Styled.SubSectionText>Contact us</Styled.SubSectionText>
            <Styled.SubText>
              We would love to create the software your company deserves. If you
              have any questions about our process, please reach out.
            </Styled.SubText>
          </FadeIn>
          {/* <a
            href="mailto:mack@resygnal.com"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Box display="flex" alignItems="center" gap={1} mt={2}>
              <Email />
              <Styled.SubText>mack@resygnal.com</Styled.SubText>
            </Box>
          </a> */}
          <Formik<FormValues>
            initialValues={{}}
            validationSchema={yupSchema}
            onSubmit={onSubmit}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              submitForm,
              submitCount,
              isSubmitting,
            }) => (
              <Form autoComplete="new-password">
                <FadeIn>
                  <Styled.FieldLabel>Name</Styled.FieldLabel>
                  <Styled.TextInput
                    error={Boolean(
                      (errors.name && touched.name) ||
                        (errors.name && submitCount > 0)
                    )}
                    onChange={handleChange}
                    autoComplete="new-password"
                    name="name"
                    onBlur={handleBlur}
                    type="text"
                    fullWidth
                    helperText={
                      ((errors.name && touched.name) || submitCount > 0) &&
                      errors.name
                    }
                    disabled={isSubmitting}
                  />
                  <Styled.FieldLabel>Email</Styled.FieldLabel>
                  <Styled.TextInput
                    error={Boolean(
                      (errors.email && touched.email) ||
                        (errors.email && submitCount > 0)
                    )}
                    onChange={handleChange}
                    autoComplete="new-password"
                    type="email"
                    name="email"
                    onBlur={handleBlur}
                    fullWidth
                    helperText={
                      ((errors.email && touched.email) || submitCount > 0) &&
                      errors.email
                    }
                    disabled={isSubmitting}
                  />
                  <Styled.FieldLabel>Number</Styled.FieldLabel>
                  <Styled.TextInput
                    error={Boolean(
                      (errors.number && touched.number) ||
                        (errors.number && submitCount > 0)
                    )}
                    onChange={handleChange}
                    autoComplete="new-password"
                    type="tel"
                    name="number"
                    onBlur={handleBlur}
                    fullWidth
                    helperText={
                      ((errors.number && touched.number) || submitCount > 0) &&
                      errors.number
                    }
                    disabled={isSubmitting}
                  />
                  <Styled.FieldLabel>Type of Work</Styled.FieldLabel>
                  <FieldArray
                    name="typeOfWork"
                    render={(helpers) => (
                      <>
                        {[
                          "Website Application Development",
                          "Cloud Hosting",
                          "IT Services & Consulting",
                          "Unsure",
                        ].map((service, idx) => (
                          <FormControlLabel
                            key={idx}
                            disabled={isSubmitting}
                            control={
                              <Styled.CheckField
                                disabled={isSubmitting}
                                onClick={() => {
                                  if (values?.typeOfWork?.includes(service)) {
                                    const idx = values?.typeOfWork?.findIndex(
                                      (type) => type === service
                                    );
                                    idx !== -1 && helpers.remove(idx);
                                  } else {
                                    helpers.push(service);
                                  }
                                }}
                                size="small"
                                sx={{
                                  "&.Mui-checked": {
                                    color: ResygnalTheme.dark,
                                  },
                                }}
                              />
                            }
                            label={
                              <Styled.ControlLabel>
                                {service}
                              </Styled.ControlLabel>
                            }
                          />
                        ))}
                      </>
                    )}
                  />
                  <Styled.FieldLabel>Message</Styled.FieldLabel>
                  <Styled.TextInput
                    disabled={isSubmitting}
                    fullWidth
                    multiLine
                    onChange={handleChange}
                    autoComplete="off"
                    multiline
                    name="message"
                    rows={5}
                  />
                  {error && (
                    <Alert sx={{ mt: 2 }} severity="error">
                      {error}
                    </Alert>
                  )}
                  <Styled.ButtonSolid
                    disabled={isSubmitting}
                    endIcon={!isSubmitting && <Styled.ThemedSend />}
                    variant="contained"
                    onClick={submitForm}
                  >
                    {isSubmitting ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Styled.ButtonSolid>
                </FadeIn>
              </Form>
            )}
          </Formik>
        </Styled.Wrapper>
      </Dialog>
    </>
  );
};
