import styled from "@emotion/styled";
import { WrapperProps } from "./PageWrapper.types";
import { ResygnalTheme } from "../../ResygnalTheme";

export const Wrapper = styled.div<WrapperProps>`
  ${({ style }) => ({ ...style })}
  height: calc(100vh - 60px);
  margin: auto;
  background: ${(props) =>
    props.useLightMode ? ResygnalTheme.light : ResygnalTheme.dark};
`;
